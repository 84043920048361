<template>
  <div class="d-inline-block">
    <h1 class="mb-6">Oeps! Toegang geweigerd</h1>
    <v-row>
      <v-col cols="2" class="d-flex justify-center align-center">
        <v-icon x-large>mdi-cancel</v-icon>
      </v-col>
      <v-col cols="10" class="d-flex justify-center align-center">
        <div>
          <p class="ma-0">
            <span>Vraag je administrator voor extra toegang.</span>
            <span class="d-inline-block">Of ga terug naar de
            <router-link :to="{name: calendarRoute}">kalender</router-link>.
          </span>
          </p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import RouteNames from "@/router/RouteNames";

export default {
  name: "Denied.vue",
  data() {
    return {
      calendarRoute: RouteNames.CALENDAR,
    }
  },
}
</script>
